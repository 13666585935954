<template>
  <!-- 帮助中心 -->
  <div id="app" style="background: #f4f4f4">
    <div class="harken-ban seqlist-ban question-ban">
      <img src="@/assets/img/question-ban.jpg" />
      <div class="words">
        <h2>帮助中心</h2>
        <div class="input">
          <input
            v-model="keyword"
            placeholder="输入项要搜索的问题"
            @keyup.enter="onSearch"
          />
          <button @click="onSearch">搜索</button>
        </div>
      </div>
    </div>
    <div class="seqlist question">
      <div class="cson-container clearfix">
        <div class="seqlist-le" :class="{ 'seqlist-le2': left_fixed }">
          <div class="help">帮助中心</div>
          <ul class="out">
            <li
              :class="type == 3 ? 'active' : ''"
              @click="onTypeTabClick('3', '课程相关')"
            >
              课程相关
              <span class="down-icon" @click="first"></span>
            </li>
            <li
              :class="type == 4 ? 'active' : ''"
              @click="onTypeTabClick('4', '实名认证')"
            >
              实名认证
              <span class="down-icon" @click="second"></span>
            </li>
            <li
              :class="type == 5 ? 'active' : ''"
              @click="onTypeTabClick('5', '视频播放')"
            >
              视频播放
              <span class="down-icon" @click="second"></span>
            </li>
            <li
              :class="type == 6 ? 'active' : ''"
              @click="onObjectTabClick('6', '网站安全')"
            >
              网站安全
            </li>
            <li
              :class="type == 7 ? 'active' : ''"
              @click="onObjectTabClick('7', '用户协议')"
            >
              用户协议
            </li>
            <li
              :class="type == 8 ? 'active' : ''"
              @click="onObjectTabClick('8', '版权保护')"
            >
              版权保护
            </li>
            <li
              :class="type == 9 ? 'active' : ''"
              @click="onObjectTabClick('9', '隐私政策')"
            >
              隐私政策
            </li>
            <li
              :class="type == 10 ? 'active' : ''"
              @click="onObjectTabClick('10', '自动续费')"
            >
              自动续费
            </li>
          </ul>
        </div>
        <div class="seqlist-rg">
          <div class="panel">
            <div class="tit">{{ currentTabName }}</div>
            <div v-if="!notList" class="content">
              <div v-if="!showDetail">
                <div
                  v-for="(item, i) in lists"
                  :key="i"
                  class="question-item"
                  @click="onQuestionItemClick(item)"
                >
                  <span></span>
                  {{ item.question }}
                </div>
              </div>
              <div v-else v-html="currentAnswer"></div>
            </div>
            <div v-else-if="notList" class="content" v-html="richText"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryFaq } from "../../service/index";
import { getIn } from "../../utils/index";

export default {
  data() {
    return {
      keyword: "",
      objectId: "",
      type: "",
      tabActive: 0,
      isLoading: false,
      showDetail: false, // 展示详情还是展示列表
      currentAnswer: "",
      currentTabName: "",
      lists: [],
      richText: "",
      notList: false,
      left_fixed: false,
    };
  },
  watch: {
    $route(to) {
      const { type, objectId } = to.query;
      this.type = type;
      console.log(type);
      this.objectId = objectId;
      if (type) {
        // this.currentTabName =
        //   type == "3" ? "课程相关" : type == "4" ? "实名认证" : "视频播放";
        this.currentTabName =
          type === "3"
            ? "课程相关"
            : type === "4"
            ? "实名认证"
            : type === "5"
            ? "视频播放"
            : type === "6"
            ? "网站安全"
            : type === "7"
            ? "用户协议"
            : type === "8"
            ? "版权保护"
            : type === "9"
            ? "隐私政策"
            : "自动续费";
        this.onQueryQA({ type });
      } else if (objectId) {
        this.onQueryQA({ objectId });
        this.currentTabName =
          objectId == "1"
            ? "用户协议"
            : objectId == "2"
            ? "网站安全"
            : objectId == "3"
            ? "版权保护"
            : "隐私政策";
      }
    },
  },
  created() {
    const { type, objectId } = this.$route.query;
    this.type = type;
    this.objectId = objectId;
    console.log(type);
    if (type) {
      this.onQueryQA({ type });
      this.currentTabName =
        type === "3"
          ? "课程相关"
          : type === "4"
          ? "实名认证"
          : type === "5"
          ? "视频播放"
          : type === "6"
          ? "网站安全"
          : type === "7"
          ? "用户协议"
          : type === "8"
          ? "版权保护"
          : "隐私政策";
    } else if (objectId) {
      this.onQueryQA({ objectId });
      this.currentTabName =
        objectId == "1"
          ? "用户协议"
          : objectId == "2"
          ? "网站安全"
          : objectId == "3"
          ? "版权保护"
          : "隐私政策";
    }
  },
  methods: {
    onSearch() {
      this.currentTabName = "搜索结果";
      this.showDetail = false;
      this.onQueryQA({
        keyword: this.keyword,
        objectId: this.objectId,
      });
    },
    async onQueryQA({ objectId, type, keyword }) {
      const result = await queryFaq({
        keyword: keyword || "",
        objectId: objectId || "",
        type: type || "",
      });
      const lists = getIn(result, ["result"]) || [];
      console.log(type);
      if (
        type == "6" ||
        type == "7" ||
        type == "8" ||
        type == "9" ||
        type == "10"
      ) {
        if (Array.isArray(lists)) {
          if (lists.length > 0) {
            this.richText = lists[0].answer;
            this.notList = true;
          } else {
            this.richText = "";
            this.notList = true;
          }
        }
      } else {
        if (Array.isArray(lists)) {
          this.lists = lists;
          this.notList = false;
        }
      }
    },
    async onTypeTabClick(type, name) {
      this.showDetail = false;
      this.objectId = "";
      this.$router.push(`/question?type=${type}`);
      this.currentTabName = name;
    },
    async onObjectTabClick(typeId, title) {
      this.showDetail = false;
      this.type = "";
      this.$router.push(`/question?type=${typeId}`);
      this.currentTabName = title;
    },
    onQuestionItemClick(item) {
      this.currentAnswer = item.answer || "";
      this.showDetail = true;
      this.currentTabName = item.question;
    },
    tabChange(index) {
      this.TabActive = index;
    },
    first() {
      this.firstOpen = !this.firstOpen;
    },
    second() {
      this.secondOpen = !this.secondOpen;
    },
    scrollTop() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.left_fixed = scrollTop > 284;
    },
  },
  mounted() {
    document.addEventListener("scroll", this.scrollTop);
    console.log(this.type);
    if (!this.type) {
      this.onTypeTabClick("3", "课程相关");
    }
  },
  destroyed() {
    //离开该页面需要移除这个监听的事件
    document.removeEventListener("scroll", this.scrollTop);
  },
};
</script>

<style lang="css" scoped>
@import "../../assets/css/guide.css";
.seqlist-le2 {
  position: fixed;
  top: 0;
  /*left:;*/
}
.question-item {
  font-size: 16px;
  color: #1c1f21;
  line-height: 22px;
  margin-top: 18px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
}

.question-item span {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: gray;
  margin-right: 10px;
}

.question-item:hover {
  color: #ec1500;
}

.seqlist .seqlist-rg .panel {
  padding-left: 0;
}

.question .seqlist-rg .panel .tit {
  padding-left: 0;
  margin-left: 0;
}

.question .seqlist-rg .panel .content p {
  color: #000;
}
</style>
